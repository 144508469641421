import React, { useEffect, useRef, useState } from 'react';
import { alpha, Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { Cell, Label, Pie, PieChart, PieLabel, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import { formatPercent } from '../../../utils/dashboardDataUtils';
import { SeveritySetting } from '../../../utils/severity.utils';
import { abbreviateNumber, dotStyle, noop } from '../../../utils/Utils';
import { PieSubType } from '../../../views/CustomDashboards/types';
import { nullValue } from '../../../views/CustomDashboards/Utils';
import { flex } from '../../AvThemeProvider';
import AvTooltip from '../../AvTooltip';
import AvTable from '../../Table/AvTable';
import CustomTooltip from '../CustomTooltip';
import { ChartClickInteraction } from '../types';
import { calculateTextWidthCavnas } from '../utils';
import { generateTopItemsPlusOthers, getPieCustomLabelMx } from './utils';

interface InnerLabelProps {
  viewBox?: Record<string, any>;
  totals: number;
  showTotalText?: boolean;
}

const InnerLabel: React.FC<InnerLabelProps> = ({ viewBox = { cx: 0, cy: 0, innerRadius: 0 }, totals, showTotalText = true }) => {
  const theme = useTheme();
  if (!totals) {
    return null;
  }

  const { cx, cy, innerRadius } = viewBox;
  return (
    <g>
      {showTotalText && (
        <text
          className="custom-chart-text"
          fontSize={Math.min(40, innerRadius / 3.5)}
          x={cx}
          y={cy - Math.min(45, innerRadius / 4)}
          textAnchor="middle"
          fill={theme.palette.colors.neutrals[500]}>
          TOTAL
        </text>
      )}
      <text
        x={cx}
        y={showTotalText ? cy + Math.min(50, innerRadius / 3) : cy}
        textAnchor="middle"
        fontSize={Math.min(50, innerRadius / 2)}
        className="custom-chart-text"
        fontWeight={600}
        fill={theme.palette.colors.neutrals[800]}>
        {abbreviateNumber(totals)}
      </text>
    </g>
  );
};

const shareCell = (v, totals) => <Box sx={{ textAlign: 'end' }}>{formatPercent(v, totals)}</Box>;
const valCell = v => <Box sx={{ fontWeight: 600, textAlign: 'end' }}>{(+v)?.toLocaleString() || v}</Box>;

interface MetricCellProps {
  v: any;
  row: any;
  chartColors: any;
  data: any[];
  maxWidth?: number;
  textColor?: { color: (val) => string };
}

const MetricCell: React.FC<MetricCellProps> = ({ v, row, chartColors, data, maxWidth = 250, textColor }) => {
  const rowIndex = data.findIndex(dataRow => dataRow === row);
  return (
    <Box sx={{ ...flex.itemsCenter, gap: 2, maxWidth }}>
      <Box sx={{ ...flex.row, ...dotStyle(chartColors[rowIndex]), flexShrink: 0 }} />
      <AvTooltip sx={textColor}>{v}</AvTooltip>
    </Box>
  );
};

const renderActiveShape = props => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, disable } = props;
  return (
    <g>
      <Sector
        style={{ cursor: disable ? 'default' : 'pointer' }}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        onClick={props?.onClick}
      />
      <Sector
        style={{ cursor: 'pointer' }}
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius - 1}
        outerRadius={outerRadius * 1.1}
        fill={fill}
        onClick={props?.onClick}
      />
    </g>
  );
};

interface LegendProps {
  variant?: 'interactive' | 'static';
  metricNameFormatter?: (v) => void;
  metricValueFormatter?: (v) => void;
  maxMetricWidth?: number;
  hideLines?: boolean;
  showShare?: boolean;
  showValue?: boolean;
}

export interface AvPieProps {
  data?: Array<Record<string, unknown>>;
  valKey?: string;
  valLabel?: string;
  metricKey?: string;
  onSelect?: (v) => void;
  selected?: { color: string; index: number; value: string }[];
  legendProps?: LegendProps;
  numOfItems?: number;
  responseTotals?: number;
  customPalette?: string[];
  sx?: Record<string, unknown>;
  showTableSelection?: boolean;
  additionalHeadCells?: Record<string, unknown>[];
  customOthersHandler?: (counts?: object, baseObj?: object, data?: []) => void;
  tooltipFields?: string[];
  showTotals?: boolean;
  showTable?: boolean;
  showLabels?: boolean;
  type?: PieSubType;
  className?: string;
  clickInteractions?: ChartClickInteraction[];
  severitySetting?: (v) => SeveritySetting;
}

type TTooltip = {
  payload: any;
  tooltipFields: string[];
  metricKey: string;
  legendProps: LegendProps;
  clickInteractions?: ChartClickInteraction[];
  areClickOptionsVisible?: boolean;
  setAreClickOptionsVisible?: (v) => void;
};
const getTooltip = ({
  payload,
  tooltipFields,
  metricKey,
  legendProps,
  clickInteractions,
  areClickOptionsVisible,
  setAreClickOptionsVisible,
}: TTooltip) => (
  <CustomTooltip
    payload={payload!}
    showTitleLegend
    showContentLegend={false}
    tooltipFields={tooltipFields}
    metricKey={metricKey}
    titleFormatter={metricKey => legendProps.metricNameFormatter?.(payload?.[0]?.payload[metricKey]) || payload?.[0]?.payload[metricKey]}
    clickInteractions={clickInteractions}
    areClickOptionsVisible={areClickOptionsVisible}
    setAreClickOptionsVisible={setAreClickOptionsVisible}
  />
);

const AvPie: React.FC<AvPieProps> = ({
  data: d = [],
  valKey = 'count',
  valLabel = 'Value',
  metricKey = 'category',
  onSelect,
  selected,
  legendProps = { variant: 'interactive', showShare: false, showValue: true },
  numOfItems,
  responseTotals,
  customPalette,
  sx = {},
  showTableSelection = false,
  additionalHeadCells = [],
  customOthersHandler,
  tooltipFields = ['count', 'share'],
  showTotals = true,
  showTable = true,
  showLabels = false,
  type,
  className,
  clickInteractions,
  severitySetting,
}) => {
  const {
    palette: { chartColors: defaultColors },
  } = useTheme();

  const chartColors = customPalette || defaultColors;
  const [active, setActive] = useState<number[]>([]);
  const isStaticLegend = legendProps.variant === 'static';
  const tableData = numOfItems ? generateTopItemsPlusOthers(d, metricKey, numOfItems, customOthersHandler) : d;
  const totals = responseTotals || tableData?.reduce((acc, entry) => acc + entry[valKey], 0);
  const pieData = tableData.map(entry => ({ ...entry, share: formatPercent(entry[valKey], totals) }));
  const [activeTooltipForClickInteractions, setActiveTooltipForClickInteractions] = useState();
  const [temporaryClickedCell, setTemporaryClickedCell] = useState<Record<string, unknown>>();
  const pieRef = useRef<any>();

  const extraHeadCells =
    additionalHeadCells.map(cell => ({
      ...cell,
      formatter: cell.formatter || valCell,
      type: cell.type || 'number',
    })) || [];
  const headCells = [
    {
      id: metricKey,
      isKey: true,
      label: metricKey,
      formatter: (v, row) =>
        MetricCell({
          v: (legendProps.metricNameFormatter?.(v) || v) === null ? nullValue : legendProps.metricNameFormatter?.(v) || v,
          row,
          chartColors,
          data: tableData,
          maxWidth: legendProps.maxMetricWidth,
          textColor: { color: theme => theme.palette.colors.neutrals[700] },
        }),
      disableSortBy: true,
    },
    {
      id: valKey,
      label: valLabel,
      hidden: !legendProps.showValue,
      type: 'number',
      formatter: v => (legendProps.metricValueFormatter ? valCell(legendProps.metricValueFormatter(v)) : valCell(v)),
      disableSortBy: true,
    },
    {
      id: 'share',
      label: 'Share',
      hidden: !legendProps.showShare,
      type: 'number',
      formatter: (v, row) => shareCell(row[valKey], totals),
      disableSortBy: true,
    },
    ...extraHeadCells,
  ];

  useEffect(() => {
    if (selected) {
      setActive(selected.map(v => v.index));
    }
  }, [selected]);

  const innerRadius = 50;
  const outerRadius = 70;

  const arrow = ({ pieRef, value, outerRadius, innerRadius }) => {
    const midAngle = 180.0 * (1 - totals / 100);
    if (pieRef.current?.container) {
      const { offsetWidth, offsetHeight } = pieRef.current.container;
      const cx = offsetWidth / 2;
      const cy = offsetHeight / 2;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const my = cy + (outerRadius + 2) * sin;
      const mx = cx + (outerRadius + 2) * cos;
      const ox = cx + (innerRadius - 2) * cos;
      const oy = cy + (innerRadius - 2) * sin;
      return (
        <g>
          <line y1={oy} x1={ox} y2={my} x2={mx} stroke="white" strokeWidth={8} />
          <line
            y1={oy}
            x1={ox}
            y2={my}
            x2={mx}
            stroke={severitySetting && severitySetting(value).color}
            strokeWidth={4}
            strokeLinecap="round"
          />
        </g>
      );
    }
    return undefined;
  };

  const handleSelection = (selectionIndex, entry) => {
    if (!active.length && clickInteractions) {
      const availableCellClickInteractions = clickInteractions.filter(v => v.isEnabledForCell(entry));
      if (availableCellClickInteractions.length === 1) {
        availableCellClickInteractions[0].onClick({ d: entry });
      } else if (availableCellClickInteractions.length) {
        setTemporaryClickedCell(entry);
      }
    } else {
      const isAlreadyActive = typeof active.find(v => v === selectionIndex) === 'number';
      const nextSelection = isAlreadyActive ? active.filter(v => v !== selectionIndex) : [...active, selectionIndex];
      setActive(nextSelection);
      onSelect?.(
        nextSelection.map(index => ({
          value: pieData[index][metricKey],
          color: chartColors[index],
          index,
        }))
      );
    }
  };

  const tableStyle = {
    ...flex.row,
    overflowY: 'auto',
    flexShrink: 0,
    py: 1.5,
    maxHeight: '100%',
    '.MuiTableRow-root': {
      cursor: 'default',
      '&.active-row': { outline: 'none' },
      '&.MuiTableRow-hover:hover': { backgroundColor: isStaticLegend ? 'transparent' : undefined },
      '.MuiTableCell-root:not(.MuiTableCell-head):not(.totals-row)': {
        borderBottomColor: isStaticLegend || legendProps.hideLines ? 'transparent' : undefined,
      },
      '&:last-of-type>.MuiTableCell-root': { borderBottomColor: legendProps.hideLines ? 'transparent' : undefined },
    },
  };

  const RADIAN = Math.PI / 180;

  const getPieLabelTextX = ({ mx, isCosPositive, pieDimMaxWidth, payload }) =>
    mx - (isCosPositive ? Math.min(pieDimMaxWidth, calculateTextWidthCavnas({ text: payload[metricKey] })) : 0);

  const showShowPieLabel = ({ percent, payload }) => percent > 0.05 && payload[metricKey];

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomPieChartLabel: React.FC<PieLabel & any> = ({ cx, cy, midAngle, outerRadius, fill, payload, value, percent, innerRadius }) => {
    const theme = useTheme();
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const isCosPositive = cos >= 0;

    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;

    const sign = isCosPositive ? 1 : -1;
    const mx = cx + getPieCustomLabelMx({ sign, innerRadius, showValue: legendProps.showValue });
    const pieDimMaxWidth = Math.abs(mx - sx) - 30;

    const textAnchor = isCosPositive ? 'end' : 'start';

    const showSeperator = legendProps.showValue && legendProps.showShare;
    const valueTextWidthInPx = legendProps.showValue
      ? calculateTextWidthCavnas({
          text: value.toLocaleString(),
          isBold: true,
        })
      : 0;

    const seperatorX = mx - sign * (valueTextWidthInPx + 8);

    return showShowPieLabel({ percent, payload }) ? (
      <g>
        <path d={`M${sx},${sy}L${mx},${sy}`} stroke={fill} fill="none" strokeWidth={2} />
        <foreignObject
          x={getPieLabelTextX({ mx, isCosPositive, pieDimMaxWidth, payload })}
          y={sy - 20}
          style={{ fontSize: '12px', color: theme.palette.colors.neutrals[600] }}
          width={pieDimMaxWidth}
          height={20}>
          <AvTooltip>{payload[metricKey]}</AvTooltip>
        </foreignObject>
        {legendProps.showValue && (
          <text
            style={{ fontWeight: 600, fontSize: '12px' }}
            x={mx}
            y={sy + 17}
            textAnchor={textAnchor}
            fill={theme.palette.colors.neutrals[800]}>
            {value.toLocaleString()}
          </text>
        )}
        {showSeperator && (
          <line x1={seperatorX} y1={sy + 6} x2={seperatorX} y2={sy + 19} stroke={theme.palette.colors.neutrals[350]} strokeWidth={1.5} />
        )}
        {legendProps.showShare && (
          <text
            x={mx - sign * (legendProps.showValue ? valueTextWidthInPx + 16 : 0)}
            y={sy + 17}
            textAnchor={textAnchor}
            fill={theme.palette.colors.neutrals[800]}
            style={{ fontSize: '12px' }}>
            {(percent * 100).toFixed(1)}%
          </text>
        )}
      </g>
    ) : undefined;
  };

  return (
    <Box
      onMouseLeave={() => {
        setActiveTooltipForClickInteractions(undefined);
        setTemporaryClickedCell(undefined);
      }}
      sx={{
        ...flex.itemsCenter,
        height: '100%',
        overflow: 'hidden',
        svg: { height: 'inherit', width: 'inherit', pointerEvents: 'unset' },
        '.recharts-surface': { overflow: 'visible' },
        ...sx,
      }}>
      <ResponsiveContainer width="50%" height={showLabels ? '75%' : '100%'} className={className}>
        <PieChart ref={pieRef} style={{ position: 'unset' }}>
          <Pie
            minAngle={10}
            isAnimationActive={false}
            data={pieData || []}
            outerRadius="90%"
            innerRadius={type === PieSubType.Donut ? '60%' : '0%'}
            dataKey={valKey}
            activeShape={renderActiveShape}
            activeIndex={active}
            startAngle={90}
            endAngle={-270}
            {...(type === PieSubType.PieWithNeedle && { startAngle: 0, endAngle: 180, innerRadius, outerRadius })}
            blendStroke
            {...(showLabels && { labelLine: false, label: <CustomPieChartLabel /> })}>
            {showTotals && (
              <Label position="center" content={<InnerLabel totals={totals} showTotalText={type !== PieSubType.PieWithNeedle} />} />
            )}
            {(pieData || []).map((entry, index) => (
              <Cell
                onMouseEnter={() => {
                  if (!temporaryClickedCell) {
                    setActiveTooltipForClickInteractions(entry);
                  }
                }}
                onMouseLeave={() => setActiveTooltipForClickInteractions(undefined)}
                onBlur={() => setActiveTooltipForClickInteractions(undefined)}
                cursor={entry.disable || type === PieSubType.PieWithNeedle ? 'default' : 'pointer'}
                filter={
                  (entry[metricKey] === activeTooltipForClickInteractions?.[metricKey] ||
                    entry[metricKey] === temporaryClickedCell?.[metricKey]) &&
                  type !== PieSubType.PieWithNeedle
                    ? `drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.60)`
                    : undefined
                }
                // eslint-disable-next-line react/no-array-index-key
                key={`${entry.id}${index}`}
                onClick={
                  (onSelect || clickInteractions?.some(({ isEnabledForCell }) => isEnabledForCell(entry))) && !entry.disable
                    ? () => handleSelection(index, entry)
                    : noop
                }
                fill={alpha(chartColors[index % chartColors.length], active.includes(index) || active.length === 0 ? 1 : 0.15)}
              />
            ))}
          </Pie>
          {type !== PieSubType.PieWithNeedle && (
            <Tooltip
              allowEscapeViewBox={{ x: true, y: true }}
              {...(clickInteractions?.some(v => v.isEnabledForCell(temporaryClickedCell))
                ? {
                    active: !!activeTooltipForClickInteractions || !!temporaryClickedCell,
                    trigger: temporaryClickedCell ? 'click' : 'hover',
                  }
                : {})}
              wrapperStyle={{ width: 'fit-content', minWidth: clickInteractions ? '230px' : '180px', zIndex: 5 }}
              content={({ payload }) =>
                getTooltip({
                  payload,
                  tooltipFields,
                  metricKey,
                  legendProps,
                  clickInteractions: active.length ? undefined : clickInteractions,
                  areClickOptionsVisible: !!temporaryClickedCell,
                  setAreClickOptionsVisible: setTemporaryClickedCell,
                })
              }
            />
          )}
          {type === PieSubType.PieWithNeedle && arrow({ pieRef, outerRadius, innerRadius, value: totals })}
        </PieChart>
      </ResponsiveContainer>
      {showTable && (
        <Box sx={tableStyle}>
          <AvTable
            sx={{ flexShrink: 0 }}
            tableContainerSx={{ overflowX: 'hidden' }}
            headCells={headCells as any}
            hasHeaderFilters={false}
            rows={tableData || []}
            allowEdit={false}
            size="xSmall"
            isSelectionControlled
            enableSelectionColor={!isStaticLegend || showTableSelection}
            onActive={noop}
            showHeader={!isStaticLegend}
            selectedKeys={active.map(v => tableData?.[v]?.[metricKey])}
          />
        </Box>
      )}
    </Box>
  );
};

export default AvPie;
